@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.p-fileupload .p-fileupload-buttonbar .p-button {
  width: 219.9px;
  height: 40px;
  padding: 20.1px 69.3px 15.5px 53.4px;
  border: solid 2px $primaryColor;
  background-color: $secondaryColor;
  color: $primaryColor;
}

.p-fileupload .p-fileupload-buttonbar .p-button:disabled {
  background-color: $secondaryColor;
  border: solid 2px $primaryColor;
}

.p-fileupload .p-fileupload-buttonbar button[aria-label='Cancel'] {
  display: none;
}
.p-button-label.p-clickable {
  color: $primaryColor;
}
.p-button .p-button-icon-left {
}
.p-button.p-button-text {
  text-decoration: underline;
  text-decoration-color: $primaryColor;
  color: $primaryColor;
  font-size: 20px;
  gap: 10px;
  padding-left: 0px;
}
.p-button.p-button-text:enabled:active {
  background: transparent;
  color: $primaryColor;
  border-color: transparent !important;
}
.p-button.p-button-text:enabled:hover {
  color: $primaryColor;
  background: transparent !important;
}
.p-button:focus {
  box-shadow: none !important;
}

.upload {
  margin-left: 35px;
}
.container-button {
  padding: 10px 0px 20px 0px;
}

.container-button-download {
  margin-bottom: 17px;
  text-align: left;
}

.downloadCsv {
  font-size: 15px;
  font-weight: 700;
}
