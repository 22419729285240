@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.footer {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #333;
}

.link {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
  text-decoration: underline;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}

.rights {
  flex-shrink: 0;
}
