@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.emailFilterContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
}

.emailFilterContainer .emailInput {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 4px;

  font-size: 1rem;
  width: 393px;
  height: 43px;
}

.emailFilterContainer .emailInput:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.emailFilterContainer .searchButton {
  margin-left: 0.5rem;
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $secondaryColor;
  height: 43px;
  width: 164.1px;
  font-size: 15px;
  padding: 0px 33px;
}

.emailFilterContainer .searchButton:hover {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.emailFilterContainer .p-input-icon-left {
  position: relative;
}

.emailFilterContainer .p-input-icon-left i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: #555;
}

.containerFilter {
  display: flex;
  justify-content: space-between;
}

.emailFilterContainer .searchButton .pi {
  font-size: 16px;
  margin-right: 4px;
}
