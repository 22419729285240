@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.containerPage {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerIcon {
  width: 116px;
  height: 116px;
  margin: 60px 38.3px 60px 66px;
  padding: 31px 31px 31px;
  background-color: #170a33;
  border-radius: 50%;
}
.titleStyle {
  font-weight: bold;
  font-stretch: normal;
  font-size: 35px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.02px;
  color: $primaryColor;
}
.containerInformation {
  padding: 15px 0px 10px 7px;
  max-width: 500px;
}
.containerDescription {
  color: #575360;
  font-weight: 700;
  padding-left: 7px;
}
.subtitleStyle {
  color: #575360;
  font-weight: bold;
  font-size: 18px;
}
.stylesText {
  color: #575360;
}

.containerText {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  margin: 30px;
}
.containerData {
  background-color: white;
  width: 775.1px;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerMain {
  padding: 0px 30px;
  margin: 40px 0px 50px 0px;
  @include mixin.tablet {
    padding: 0px 63px;
  }
}
.titleForm {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  margin: 0px;
  line-height: normal;
}

.subtitle {
  font-family: ApercuPro;
  font-size: 18px;
  font-weight: normal;
  color: $formSubTitle;
}
.personalInf {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}

.containerVisa {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-bottom: 15px;
}

.p-button-label {
  font-size: 22px;
  font-weight: bold;
}
.containerForSecurity {
  display: flex;
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  padding: 10px 70px;
  color: #6b6b6b;
  margin: 0px;
  margin-bottom: 10px;
}

.divider {
  display: flex;
  border-top: solid 1px #dedee8;
  opacity: 0.49;
  width: 100%;
  padding: 10px 0px;
}

.visaLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
