@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';
.mainSection {
  margin-top: 50px;
  display: flex;
  flex: 1;
  width: 100%;
}

.mainSectionWorkArea {
  background-color: $primaryBackgroundColor;
  width: 100%;
  padding: 50px 30px 20px 35px;
  flex: 1;
}
.containerData {
  background-color: $primaryBackgroundColor;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  font-size: 45px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: $primaryColor;
}

.userSettings {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 151.7px;
  padding: 28px 40px 40px 30px;
  border-radius: 9px;
  border: solid 2px #dfe1e3;
  background-color: #fff;
}

.settingsSection {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 100px;
}

.settingsLabel {
  font-family: ApercuPro;
  font-size: 20px;
  color: #575360;
  margin-right: 10px;
  line-height: 2;
  font-weight: normal;
}

.settingsValue {
  flex-grow: 1;
  font-family: ApercuPro;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  color: #170a33;
}
.settingsValueEmail {
  flex-grow: 1;
  font-family: ApercuPro;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  color: #170a33;
}
.settingsLabelEmail {
  font-family: ApercuPro;
  font-size: 21px;
  color: #170a33;
  font-weight: bold;
  margin-right: 10px;
  line-height: 2;
}

.settingsAction {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.styleIcon {
  padding: 5px 0px 0px 2px;
}

.p-button-text {
  text-decoration: underline;
  border: none;
  background: none;
  padding-left: 5px;
}
.containerConten {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.separator {
  height: 70px;
  border-left: 2px solid #dfe1e3;
  margin: 0px;
}
.buttonsChange {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  color: #170a33;
  background: none !important;
  border: none !important;
  text-decoration: underline;
}
.buttonsChange:hover {
  color: #170a33 !important;
}
.buttonsChange:focus {
  box-shadow: none !important;
}
.buttonsChange:active {
  color: #170a33 !important;
}
.documents {
  font-size: 35px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.66;
  color: #170a33;
}
.containerDocuments {
  padding: 40px 0px;
}
.containerCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 113px;
  border-radius: 9px;
  border: solid 2px #dfe1e3;
  padding: 24.2px 30.4px 22.5px 23.8px;
}
.containerIconDow {
  display: flex;
  align-items: center;
  gap: 5px;
}
.iconFile {
  width: 66.4px;
  height: 66.4px;
  margin: 0 23.3px 0 0;
  padding: 20.7px 23.5px 20.7px 23.9px;
  border-radius: 9px;
  background-color: #170a33;
}
.containerCardSettings {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
}
.settingsCard {
  flex: 0 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  box-sizing: border-box;
}
.settingsCard:last-child {
  flex-basis: 100%;
}
.footer {
  margin-top: auto;
  padding: 20px 0;
}

.cardLink {
  text-decoration: none;
  color: inherit;
}
