@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.containerPage {
  background-color: #f7f7f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  background-color: white;
  width: 100%;
  height: 100%;
  @include mixin.tablet {
    max-width: 610px;
    height: auto;
  }
}
.content {
  padding: 40px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  @include mixin.tablet {
    gap: 20px;
    padding: 40px 60px;
    text-align: left;
    flex-direction: row;
  }
}
.title {
  color: #000;
  margin-bottom: 10px;
}
.footer {
  padding: 20px 30px;
  text-align: center;
  border-top: #dedee8 1px solid;

  @include mixin.tablet {
    padding: 20px 60px;
  }
}

.bold {
  font-family: ApercuPro;
  font-size: 16px;
  letter-spacing: -0.49px;
  color: #6b6b6b;
}

.description {
  font-family: ApercuPro;
  font-size: 16px;
  letter-spacing: -0.43px;
  color: #6b6b6b;
  margin: 0;
  margin-bottom: 10px;
}

.containerForSecurity {
  font-family: ApercuPro;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  text-align: center;
  color: #6b6b6b;
}
