@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.disbursementWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 0 0 28px 0;
  margin-top: 50px;
  width: 826px;
}
.inputGroup {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  > h4 {
    margin-right: 10px;
  }
  .currencyContainer {
    .dropdownStyle {
      width: 223px;
      height: 42px;
      padding: 10px;
    }
  }

  .icon {
    color: black !important;
    z-index: 100;
  }

  .amountContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    .inputTextStyle {
      width: 493px;
      border-left: transparent !important;
      height: 42px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .containerError {
    display: flex;
    flex-direction: column;
  }

  .cardNumberContainer {
    width: 371px;
    height: 42px;
  }
}

.conceptContainer {
  display: flex;
  flex-direction: column;
  .inputConcept {
    width: 762px;
    height: 42px;
  }
}
.containerSingleTab {
  padding: 15px !important;
}

.descriptions {
  margin-bottom: 0px;
}

.tabButtonContainer {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 32px;
  overflow: hidden;
}
.activeButton {
  background-color: $secondaryColor !important;
  border-radius: 4px;
  border: solid 2px #170a33;
  width: 373px;
  height: 48px;
  color: #170a33 !important;
}
.customButton {
  width: 373px;
  height: 48px;
  background-color: #f4f6f8 !important;
  color: #170a33 !important;
  border: none !important;
}
.containerButtonsDis {
  display: flex;
  justify-content: flex-end;
  padding: 25px;
}

.buttonCancel {
  display: flex;
  justify-content: center;
  margin-right: 20px !important;
  width: 118px;
  height: 47px;
  border-radius: 4px;
  background-color: #f4f6f8 !important;
  color: #170a33 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 17px;
  font-weight: 700;
}

.buttonContinue {
  width: 164px;
  height: 47px;
  border-radius: 4px;
  background-color: #170a33 !important;
  color: #00b4ff !important;
  box-shadow: none !important;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 5px;
  text-decoration: none !important;
}

a.buttonContinue,
a.buttonContinue:hover,
a.buttonContinue:focus {
  text-decoration: none;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.buttonContinue .rightIcon {
  margin-left: 10px;
}

.inputWrapper {
  position: relative;

  .inputIcon {
    position: absolute;
    font-size: 16px;
    left: 10px;
    top: 70%;
    transform: translateY(-50%);
  }

  .cardNumberContainer {
    padding-left: 30px;
  }
}

.conceptContainer {
  display: flex;
  flex-direction: column;
  .inputEmail {
    width: 762px;
    border-left: transparent !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.emailContainer {
  display: flex;
  align-items: center;
  border-right: transparent !important;
  border: 1px solid #cccccc;
  background: transparent !important;
  padding-left: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.disclaimer {
  margin-left: 35px;
  margin-bottom: 7px;
  font-size: 24px;
  font-weight: bold;
  color: $whiteColor;
}
.containerDisclaimer {
  width: 753px;
  height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 10px;
  background-color: #170a33;
  margin: 30px 30px 5px 37px;
}
.inputError {
  border-color: red !important;
  * {
    border-color: red !important;
  }
}
