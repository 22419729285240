@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.tableWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 15px 50px 496px 51px;
  padding: 35px 30px 30.3px 34px;
}
