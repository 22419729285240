@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.error-message-container {
  display: flex;
  align-items: center;
  background-color: #fbeae5;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;

  .error-message-icon {
    font-size: 24px;
    color: $errorUpload;
    margin-right: 10px;
  }

  .error-message-text {
    strong {
      display: block;
      font-weight: bold;
      color: $errorUpload;
      font-size: 16px;
    }
  }
  .detail {
    color: $errorUpload;
    font-size: 14px;
  }
}
