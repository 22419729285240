@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.exportButton {
  background: $secondaryColor;
  border: solid $blackColor 3px;
  color: $blackColor !important;
  margin: 10px 0 10px 0;
  width: 188.5px;
  height: 43px;
}
