@import '@styles/variables.scss';

.statusTypeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 27px;
  padding: 0.5em;
  gap: 15px;
  cursor: pointer;
}

.icon {
  padding-right: 0.3em;
  font-size: 12px;
}

.black {
  color: $blackColor;
  font-size: 12px;
}

.red {
  font-size: 14px;
  color: $blackColor;
}
