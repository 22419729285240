@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.containerPage {
  background-color: #f7f7f7;
}
.containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerPersonalInformation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mixin.tablet {
    flex-direction: row;
  }
}
.containerData {
  background-color: white;
  width: 100%;
  max-width: 768px;
}
.containerMain {
  padding: 0px 30px;
  margin: 40px 0px 50px 0px;
  @include mixin.tablet {
    padding: 0px 63px;
  }
}
.titleForm {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  margin: 0px;
  line-height: normal;
}

.subtitle {
  font-family: ApercuPro;
  font-size: 18px;
  font-weight: normal;
  color: $formSubTitle;
}
.personalInf {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}
.notes {
  font-size: 14px;
  font-weight: normal;
  margin: 5px 0px 15px 0px;
  color: $formSubTitle;
}
.inputsForm {
  width: 100%;
  margin-bottom: 10px;
  @include mixin.tablet {
    width: 49%;
  }
}

.formInput {
  border-radius: 5px;
  height: 40px;
}
.formInputCard {
  height: 40px;
  border-radius: 5px;
}
.labelForm {
  font-size: 14px;
  font-weight: 600;
}
.containerBillingA {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.containerCountry {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @include mixin.tablet {
    flex-direction: row;
  }
}
.inputConuntry {
  margin-bottom: 10px;
  width: 100%;
  @include mixin.tablet {
    width: 50%;
  }
}
.inputCity {
  width: 100%;
  margin-bottom: 10px;

  @include mixin.tablet {
    width: 24%;
  }
}
.inputZipCode {
  width: 100%;
  margin-bottom: 10px;

  @include mixin.tablet {
    width: 24%;
  }
}
.containerStreetAddress {
  margin-top: 20px;
}
.billingInformation {
  margin-top: 40px;
}
.containerVisa {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  margin-bottom: 15px;
}
.inputCard {
  width: 100%;

  @include mixin.tablet {
    width: 50%;
  }
}
.inputTypeAsset {
  display: none;

  @include mixin.tablet {
    display: flex;
  }
}

.cardInputs {
  display: flex;
  gap: 5px;
  margin-top: 15px;
  flex-direction: column;

  @include mixin.tablet {
    flex-direction: row;
  }
}
.cardNumerContainer {
  width: 100%;

  @include mixin.tablet {
    width: 69%;
  }
}
.containerCheckBox {
  margin: 30px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.checkbox {
  width: 23px;
  height: 23px;
  border-radius: 4px;
}

.containerButtonsDis {
  display: flex;
  justify-content: flex-end;

  width: 364px;
}
.buttonContinue {
  height: 66.8px;
  border-radius: 4px;
  background-color: $primaryColor !important;
  color: $whiteColor !important;
  box-shadow: none !important;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 5px;
  text-decoration: none !important;
  padding: 30px 90px;
  border-radius: 5px;
}
.buttonContinue .rightIcon {
  margin-left: 10px;
}
.p-button-label {
  font-size: 22px;
  font-weight: bold;
}
.containerForSecurity {
  display: flex;
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  text-align: center;
  color: #6b6b6b;
}
.visaLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelLink {
  font-size: 14px;
  color: #000;
  cursor: pointer;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
}
.linkContainer {
  color: #00a7e5 !important;
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
}

.inputError {
  border: 1px solid red;
}
.spinnerContainer {
  display: flex;
  margin-top: 20px;
}
