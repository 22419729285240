@import '@styles/variables.scss';

.filterSectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterSection {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}

.filterInfoSection h4 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.filterInfoSection p {
  margin: 2px 0 2px 0;
  color: $primaryColor;
}

.refreshSeccion {
  display: flex;
  align-items: center;
  gap: 20px;
}

.reload {
  background-color: $primaryColor;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchSection {
  display: flex;
  align-items: stretch;
  border-radius: 3px solid;
  width: 393px;
}

.searchButton {
  height: 43px;
  margin: 0 0 0 16.2px;
  background-color: $primaryColor;
  color: $secondaryColor;
  border: none;
}

.searchButton:hover {
  background-color: $primaryColor !important;
  color: $secondaryColor !important;
  opacity: 0.8;
}

.lastRefreshLabel {
  font-size: 14px;
  color: $lightTextColor;
}

.refreshButton {
  text-decoration: underline;
  color: $primaryColor;
  border: transparent;
  background-color: transparent;
  width: fit-content;
  height: 43px;
  padding: 13px 5px 11.5px 5px;
}

.refreshButton:hover {
  opacity: 0.8;
  color: $primaryColor !important;
  border: transparent !important;
  background-color: $whiteColor !important;
}

.refreshButton:active,
.refreshButton:focus {
  background-color: $whiteColor;
  border: none;
  outline: none;
  box-shadow: none;
}

.searchButton .p-button-label {
  color: $secondaryColor;
}

.searchButton span span.p-button-label.p-c {
  font-size: 18px;
}

.filterComponent {
  height: 43px;
  border-radius: 3px;
  border: solid 1px $lightLineColor;
  background-color: $whiteColor;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: ApercuPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.dropdownComponent {
  height: 43px;
  border-radius: 3px;
  border: solid 1px $lightLineColor;
  background-color: $whiteColor;
  font-family: ApercuPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  width: auto !important;
}
.dateStyle {
  font-weight: bold;
  font-size: 15px;
}

.searchInput {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 43px;
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 245px;
}

.errorMessage {
  color: $warningColor;
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  position: absolute;
}

.customIconColor {
  color: #170a33;
  font-weight: bold;
}

.containerReset {
  display: flex;
  align-items: center;
}
.containerArrowReset {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #170a33;
  border-radius: 20px;
  width: 27px;
  height: 26px;
}
.containerLastR {
  display: flex;
  justify-content: end;
}
.filterInfoSection {
  padding: 0px 15px;
  min-width: fit-content;
}

.containerData {
  line-height: 1.3;
}
