@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.success-message-container {
  display: flex;
  align-items: center;
  background-color: #e7fcec;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;

  .success-message-icon {
    font-size: 24px;
    color: $successUpload;
    margin-right: 10px;
  }

  .success-message-text {
    strong {
      display: block;
      font-weight: bold;
      color: $successUpload;
      font-size: 16px;
    }
  }
  .detail {
    color: $successUpload;
    font-size: 14px;
  }
}
