@import '@styles/variables.scss';

.statusTypeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 27px;
  padding: 0.5em;
  gap: 2px;
  border-radius: 5px;
}

.icon {
  padding-right: 0.3em;
  font-size: 12px;
}

.black {
  color: $blackColor;
}

.orange {
  color: $orangeColor;
  font-weight: bold;
  font-size: 12px;
}

.blue {
  color: #167fe8;
  font-weight: bold;
  font-size: 12px;
}
.green {
  color: $completedStatus;
  font-weight: bold;
  font-size: 12px;
}

.red {
  color: $errorStatus;
  font-weight: bold;
  font-size: 14px;
}

.orangeBorder {
  border: solid 1px $orangeColor;
  background-color: $orangeBackground;
}
.blueBorder {
  border: solid 1px $blueColor;
  background-color: $blueBackgground;
}

.greenBorder {
  border: solid 1px $completedStatus;
  background-color: $greenBackground;
}

.redBorder {
  border: solid 1px $errorStatus;
  background-color: $error_bkg;
}
