@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  border-bottom: solid $lightLineColor 1px;
  z-index: 3;
}

.hideShowSideButton {
  color: $primaryColor !important;
  background: white !important;
  border: white !important;
  border-radius: 90px;
}

.hideShowSideButton:hover {
  background-color: rgb(23, 10, 51, 0.08) !important;
}

.headerMenuContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 201px;
  background: $primaryColor;
  height: 100%;
}

.containerLogo {
  margin-left: 20px;
}
